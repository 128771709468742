<template>
  <div>
    <div>
      <b-img :src="content.imgSrc" fluid :alt="content.imgAlt"></b-img>
    </div>
    <div class="mx-auto mt-5 mb-3" style="width: 400px; white-space: nowrap;">
      <h1>{{ content.title }}</h1>
    </div>
    <div class="mt-5  mb-3">
      <b-card
      no-body
      class="overflow-hidden mb-3 border-0 bg-light"
      v-for="(item, index) of content.detail.sections"
      :key="index"
    >
        <b-row no-gutters>
          <b-col md="6" v-show="item.left">
            <b-card-img
              :src="item.imgSrc"
              :alt="item.imgAlt"
              :class="item.class"
            ></b-card-img>
          </b-col>
          <b-col md="6">
            <b-card-body :title="item.title">
              <b-card-text>
                {{ item.text }}
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="6" v-show="item.right">
            <b-card-img
              :src="item.imgSrc"
              :alt="item.imgAlt"
              :class="item.class"
            ></b-card-img>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "solution",
  data() {
    return {
      id: 1,
      solutions: [
        {
          title: "消费品行业解决方案",
          imgSrc: "https://picsum.photos/1024/400/?image=41",
          imgAlt: "Image",
          imgTop: true,
          text:
            "深入消费品行业，提供消费品行业的全行业解决方案，包括销售自动化、费用控制、促销管理、经销商管理等，提升消费品行业的业务流程的IT化和智能化进程。",
          to: "/s/1",
          span: "查看详情 ...",
          detail: {
            sections: [
              {
                title: "here is section1",
                text: "here is section1",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section2",
                text: "here is section2",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
              {
                title: "here is section3",
                text: "here is section3",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section3",
                text: "here is section4",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
            ],
          }
        },
        {
          title: "促销管理解决方案",
          imgSrc: "https://picsum.photos/1024/400/?image=41",
          imgAlt: "Image",
          imgTop: true,
          text:
            "对促销活动的全流程管理，从年度预算、计划管理、费用分摊、促销执行监控、费用核销到计划ROI分析以及对未来计划的优化分析，从而提升促销方案的执行效果。",
          to: "/s/2",
          span: "查看详情 ...",
          detail: {
            sections: [
              {
                title: "here is section1",
                text: "here is section1",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section2",
                text: "here is section2",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
              {
                title: "here is section3",
                text: "here is section3",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section3",
                text: "here is section4",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
            ],
          }
        },
        {
          title: "费用管控解决方案",
          imgSrc: "https://picsum.photos/1024/400/?image=41",
          imgAlt: "Image",
          imgTop: true,
          text:
            "对促销费用使用的全程跟踪和分析，包括费用计划、分摊、核销，以及费用的流程管理。",
          to: "/s/3",
          span: "查看详情 ...",
          detail: {
            sections: [
              {
                title: "here is section1",
                text: "here is section1",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section2",
                text: "here is section2",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
              {
                title: "here is section3",
                text: "here is section3",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section3",
                text: "here is section4",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
            ],
          }
        },
        {
          title: "主数据解决方案",
          imgSrc: "https://picsum.photos/1024/400/?image=41",
          imgAlt: "Image",
          imgTop: true,
          text:
            "提供一种主数据管理的方案，使得企业能够有效地管理存储在不同系统中的数据，创建和维护业务数据的一致性、完整性、相关性和精确性。提供共享的数据中心，使之成为一系列以客户为中心的业务流程和服务，支持数据的多用户管理，以及提供主数据管理和维护的流程能力。",
          to: "/s/4",
          span: "查看详情 ...",
          detail: {
            sections: [
              {
                title: "here is section1",
                text: "here is section1",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section2",
                text: "here is section2",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
              {
                title: "here is section3",
                text: "here is section3",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section3",
                text: "here is section4",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
            ],
          }
        },
        {
          title: "经销商管理解决方案",
          imgSrc: "https://picsum.photos/1024/400/?image=41",
          imgAlt: "Image",
          imgTop: true,
          text:
            "管理与经销商的合作、活动计划跟踪、费用核销跟踪以及产品销量分析等。",
          to: "/s/5",
          span: "查看详情 ...",
          detail: {
            sections: [
              {
                title: "here is section1",
                text: "here is section1",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section2",
                text: "here is section2",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
              {
                title: "here is section3",
                text: "here is section3",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section3",
                text: "here is section4",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
            ],
          }
        },
        {
          title: "客户管理解决方案",
          imgSrc: "https://picsum.photos/1024/400/?image=41",
          imgAlt: "Image",
          imgTop: true,
          text:
            "以客户为中心，管理客户合作计划、促销活动执行跟踪、费用跟踪和核销、以及P&L分析等。",
          to: "/s/6",
          span: "查看详情 ...",
          detail: {
            sections: [
              {
                title: "here is section1",
                text: "here is section1",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section2",
                text: "here is section2",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
              {
                title: "here is section3",
                text: "here is section3",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0 bg-light",
                left: true,
              },
              {
                title: "here is section3",
                text: "here is section4",
                imgSrc: "https://picsum.photos/300/300/?image=41",
                imgAlt: "Card image",
                class: "mb-3 border-0",
                right: true,
              },
            ],
          }
        },
      ],
    };
  },
  beforeRouteUpdate(to, from, next) {
    console.log(to, from);
    if (to.path === from.path) {
      const newQuery = Object.assign({}, to.params);
      const oldQuery = Object.assign({}, from.params);
      if (JSON.stringify(newQuery) !== JSON.stringify(oldQuery)) {
        console.log("load again");
        this.load();
      }
    } else {
      console.log("load since path is change");
      next();
      this.load();
    }
  },
  computed: {
    content: function() {
      return this.solutions[this.id - 1];
    },
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.id = +this.$route.params["id"];
    },
  },
};
</script>
